export class Document {
  name: string;

  constructor(attrs?: string) {
    if (!attrs) {
      return;
    }
    this.name = attrs;
  }
}
