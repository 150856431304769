import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IChildren } from '@interfaces/admin/children.interface';

import { Children } from '@models/admin/children';
import { map } from 'rxjs/operators';

/**
 * @autor Adrian Avendano Concha
 * @date 20/01/2022
 */
@Injectable({
  providedIn: 'root',
})
export class ChildrenService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  /**
   * @autor Adrian Avendano Concha
   * @date 20/01/2022
   */
  getChildrenBySchool(school_id: string): any {
    let url = `${environment.API_ENDPOINTS}/aolservices/children`;
    url += `?school=${school_id}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        if (response.error) {
          return { error: response.error };
        }
        return response.children.map((child: IChildren) => {
          return new Children(child);
        });
      })
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 20/01/2022
   */
  getChildrenByValue(value: string): any {
    let url = `${environment.API_ENDPOINTS}/aolservices/children`;
    url += `?value=${value}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        if (response.error) {
          return { error: response.error };
        }
        return response.children.map((child: IChildren) => {
          return new Children(child);
        });
      })
    );
  }

  getDocusignSignings(child_id: string): any {
    const url = `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/docusignSigningsStudent?childId=${child_id}`;
    return this.httpClient.get(url);
  }

  getDocumentPfd(child_id: string, envelope_id: string): any {
    return this.httpClient.get(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/childrenSignesDocuments?childId=${child_id}&envelopeId=${envelope_id}`
    );
  }

  updateStateDocusign(docusing_signing_id: string, data: any): any {
    return this.httpClient.post(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/docusignSigningsStudent?docusingSigningId=${docusing_signing_id}`,
      data
    );
  }

  CalificationsDocuments(data: any): any {
    return this.httpClient.post(
      `https://api.innovaschools.co/qualificationServices/gradeBook`,
      data
    );
  }

  signingDocuments(schoolId: string, schoolGrade: string): any {
    return this.httpClient.get(
      `https://7153g3fst0.execute-api.us-east-1.amazonaws.com/Prod/enrollmentSheet?schoolId=${schoolId}&schoolGrade=${schoolGrade}`
    );
  }

  printDocuments(
    schoolId: string,
    schoolGrade: string,
    mergerPdf: boolean
  ): any {
    return this.httpClient.get(
      `https://7153g3fst0.execute-api.us-east-1.amazonaws.com/Prod/enrollmentSheet?schoolId=${schoolId}&schoolGrade=${schoolGrade}&mergerPdf=${mergerPdf}`
    );
  }
  printDocumentsCalifications(data: any): any {
    return this.httpClient.post(
      `https://api.innovaschools.co/qualificationServices/gradeBook`,
      data
    );
  }
}
