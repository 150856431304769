import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(private httpClient: HttpClient) {}

  getChildInscriptionPricing(childId: string): any {
    return this.httpClient.get(
      `${environment.API}/children/${childId}/inscription`
    );
  }

  getChildRenovacionPricing(childId: string): any {
    return this.httpClient
      .get(
        `${environment.API_ENDPOINTS}/aolutilities/paymentrenovation?childId=${childId}`
      )
      .toPromise();
  }

  getChildMatriculaPricing(childId: string): any {
    return this.httpClient.get(
      `${environment.API}/children/${childId}/matriculacion`
    );
  }

  getTuitonDiscount(code: string, child: string, renovation: boolean): any {
    if (renovation == true) {
      return this.httpClient.get(
        `${environment.API_ENDPOINTS}/aolutilities/discounts?code=${code}&child=${child}&renovation=true`
      );
    }
    return this.httpClient.get(
      `${environment.API_ENDPOINTS}/aolutilities/discounts?code=${code}&child=${child}`
    );
  }
}
