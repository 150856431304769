export class Permission {
  create: boolean;
  delete: boolean;
  description: string;
  download: boolean;
  module_id: string;
  name: string;
  read: boolean;
  update: boolean;
  upload: boolean;

  constructor(attrs?: any) {
    this.create = attrs.create;
    this.delete = attrs.delete;
    this.description = attrs.description;
    this.download = attrs.download;
    this.module_id = attrs.module_id;
    this.name = attrs.name;
    this.read = attrs.read;
    this.update = attrs.update;
    this.upload = attrs.upload;
  }
}
