import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ParentPayload } from '@payloads/parent.payload';

@Injectable({
  providedIn: 'root',
})
export class TutorService {
  constructor(private httpClient: HttpClient) {}

  setTutors(payload: any): any {
    return this.httpClient.put(`${environment.API}/tutors`, payload);
  }

  invoicingRegistration(payload: any): any {
    return this.httpClient.post(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/inovicingInformation`,
      payload
    );
  }
}
