import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpClient: HttpClient) {}

  private endpoint =
    'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKGetAllProducts';

  getListProductByCategory(category: string): any {
    return this.httpClient.get(`${this.endpoint}?categoria=${category}`);
  }

  getProductById(product: string): any {
    return this.httpClient.get(`${this.endpoint}?producto=${product}`);
  }

  saveProduct(product: any): any {
    return this.httpClient.post(this.endpoint, product);
  }

  uploadProductImage(formData: any): any {
    return this.httpClient.post(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKSaveProductImage',
      formData
    );
  }
}
