import { IActivitie } from '@interfaces/activitie.interface';
import { IExtraService } from '@interfaces/extra.interface';
import { Activitie } from './activitie';

export class ExtraService {
  activities: Activitie[];
  name: string;
  school_id: string;

  constructor(attrs?: IExtraService) {
    if (!attrs) {
      return;
    }
    this.activities = this.mapActivities(attrs.activities);
    this.name = attrs.name;
    this.school_id = attrs.school_id;
  }

  private mapActivities(activities: IActivitie[]): any {
    return activities.map((activitie: IActivitie) => {
      return new Activitie(activitie);
    });
  }
}
