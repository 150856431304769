import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Document } from '@models/document';
import { map } from 'rxjs/operators';

/**
 * @author Adrian Avendano Concha
 * @date 14/01/2022
 */
@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Obtener los tipos de documentos
   * @author Adrian Avendano Concha
   * @date 14/01/2022
   */
  getDocumentTypes(): any {
    return this.httpClient.get(`${environment.API3}/documentType`).pipe(
      map((res: string[]) => {
        return res.map((document: string) => {
          return new Document(document);
        });
      })
    );
  }
}
