import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { InfoPayment } from '@models/info-payment';
import { CookieService } from 'ngx-cookie-service';

/**
 * @autor Adrian Avendano Concha
 * @date 25/01/2022
 */
@Injectable({
  providedIn: 'root',
})
export class CookiePaymentService {
  constructor(private cookieService: CookieService) {}

  /**
   * Crear una cookie con el index del child seleccionado.
   * @autor Adrian Avendano Concha
   * @date 27/01/2022
   */
  setCookiePaymentPension(payment: InfoPayment): void {
    this.cookieService.delete(environment.SELECTED_PAYMENT_COOKIE);
    const encoded = btoa(JSON.stringify(payment));
    this.cookieService.set(
      environment.SELECTED_PAYMENT_COOKIE,
      encoded,
      null,
      '/'
    );
  }

  getCookiePaymentPension(): any {
    return JSON.parse(
      atob(this.cookieService.get(environment.SELECTED_PAYMENT_COOKIE))
    );
  }

  setCookiePayment(payment: any): void {
    this.cookieService.delete(environment.SELECTED_PAYMENT_COOKIE_ANY);
    const encoded = btoa(JSON.stringify(payment));
    this.cookieService.set(
      environment.SELECTED_PAYMENT_COOKIE_ANY,
      encoded,
      null,
      '/'
    );
  }

  getCookiePayment(): any {
    return JSON.parse(
      atob(this.cookieService.get(environment.SELECTED_PAYMENT_COOKIE_ANY))
    );
  }
}
