import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltersRouteRequestInput } from '@interfaces/filters-route.request.input';
import { IRouteRequestInput } from '@interfaces/route-request.input';
import { IRouteRequest } from '@interfaces/route-request.interface';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/route-school-requests';

@Injectable({
  providedIn: 'root',
})
export class RouteRequestsService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  getAllRouteRequests(params: Partial<FiltersRouteRequestInput> = {}) {
    return this.httpClient.get(ApiURL, { params });
  }

  saveRouteRequest(routeRequestInput: IRouteRequestInput) {
    return this.httpClient.post(ApiURL, routeRequestInput);
  }

  updateRouteRequest(routeRequestInput: Partial<IRouteRequest>) {
    return this.httpClient.put(ApiURL, routeRequestInput);
  }
}
