import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Session } from '@models/session';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private httpClient: HttpClient) {}

  createSession(session: Session): any {
    const headers = new HttpHeaders();
    return this.httpClient.post(
      `${environment.API}/login`,
      {
        session: {
          email: session.email.toLowerCase(),
          password: session.password,
        },
      },
      { headers }
    );
  }

  exchangeAuthCodeToken(code: string): any {
    localStorage.setItem('federated', 'true');

    const url = environment.cognito_endpoint + environment.cognito_oauth2;
    const clientId = environment.clientId;
    const appEnpoint = environment.app_endpoint;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const body = `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=${appEnpoint}`;

    return this.httpClient.post(url, body, { headers });
  }
}
