export const SchoolGrades = [
  'ETAPA 1 - Prejardín',
  'ETAPA 1 - Jardín',
  'ETAPA 1 - Transición',
  'ETAPA 1 - Primero',
  'ETAPA 2 -Segundo',
  'ETAPA 2 - Tercero',
  'ETAPA 2 - Cuarto',
  'ETAPA 2 - Quinto',
  'ETAPA 3 - Sexto',
  'ETAPA 3 - Séptimo',
  'ETAPA 3 - Octavo',
  'ETAPA 4 -Noveno',
  'ETAPA 4 -Decimo',
  'ETAPA 4 -Once',
];
