import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IExtraService } from '@interfaces/extra.interface';
import { ExtraService } from '@models/extra';
import { map } from 'rxjs/operators';

/**
 * @autor Adrian Avendano Concha
 * @date 25/01/2022
 */
@Injectable({
  providedIn: 'root',
})
export class ExtrasService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Obtener los servicios extracurriculares de cada sede
   * @autor Adrian Avendano Concha
   * @date 21/02/2022
   */
  getExtraServices(childid: string): any {
    // console.log("estoy en getExtraServices de extra.service ")
    /*  let url = `assets/dummi/extra.json`; */
    let url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
    /*      let url = `${environment.API3}/AolExtracurriculares`; */
    url += `?child_id=${childid}`;

    return this.httpClient.get(url).pipe(
      map((response: IExtraService[][]) => {
        return response[0].map((service: IExtraService) => {
          // console.log("GET EXTRA IN EXTRA SERVICE", new ExtraService(service))
          return new ExtraService(service);
        });
      })
    );
  }

  getExtraServicesAll(childid: string): any {
    //  let url = `assets/dummi/extra.json`;
    let url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
    /*      let url = `${environment.API3}/AolExtracurriculares`; */
    url += `?child_id=${childid}`;
    // console.log('si llego al extraservice')
    // console.log("url", url)
    return this.httpClient.get(url).pipe(
      map((response: IExtraService[][]) => {
        return response[1].map((service: IExtraService) => {
          // console.log("GET EXTRA SERVICES ALL IN EXTRASERVICE", new ExtraService(service))
          return new ExtraService(service);
        });
      })
    );
  }

  deleteExtraPensiones(payload: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/UserExtracurriculares`;
    return this.httpClient.delete(url, payload);
  }

  getExtrasBack(): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;

    return this.httpClient.get(url).pipe(
      map((response: IExtraService[]) => {
        return response.map((service: IExtraService) => {
          // console.log("new ExtraService(service)", new ExtraService(service))
          return new ExtraService(service);
        });
      })
    );
  }

  postExtrasBack(payload: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;

    return this.httpClient.post(url, payload);
  }

  putExtrasBack(payload: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;

    return this.httpClient.put(url, payload);
  }

  deleteExtrasBack(parameters: any): any {
    let url = `${environment.API_ENDPOINTS}/aolutilities/BackOfficeExtracurriculares`;

    url += `?${parameters}`;
    return this.httpClient.delete(url);
  }

  uploadImageExtraBack(formData: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/UploadImageExtracurriculares`;

    return this.httpClient.post(url, formData);
  }
}
