import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISelectedLabels } from '../interfaces/selected-labels.interface';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  constructor(private httpClient: HttpClient) {}

  generateAddress(child_id: string, address_info: any, recoger: string): any {
    const cart = {
      child_id,
      address_info,
      recoger,
    };
    return this.httpClient.post(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKAddressCrud',
      cart
    );
  }

  findAddressByChild(child_id: string): any {
    const ruta =
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKAddressCrud?child_id=' +
      child_id;
    return this.httpClient.get(ruta);
  }

  addToCart(child_id: string, stock_id: string, quantity: number): any {
    const cart = {
      child_id,
      stock_id,
      quantity: String(quantity),
    };
    return this.httpClient.post(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKAddToCart',
      cart
    );
  }

  getCart(child_id: string): any {
    return this.httpClient.get(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKGetCart?child_id=' +
        child_id
    );
  }

  generateStock(
    product_id: string,
    labels: ISelectedLabels[]
  ): Observable<any> {
    const stock = { product_id, labels };
    const ruta =
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKgetProductStock';
    return this.httpClient.post<any>(ruta, stock);
  }

  deleteProductFromCart(child_id: string, stock_id: string): any {
    const stock = { child_id, stock_id, quantity: -1 };
    const ruta =
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKAddToCart';
    return this.httpClient.post<any>(ruta, stock);
  }

  generateBuyingOrder(
    child_id: string,
    address_id: string,
    user_id: string
  ): any {
    const cart = {
      child_id,
      address_id,
      user_id,
    };
    return this.httpClient.post(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKGenerateBuyingOrder',
      cart
    );
  }

  sendEmail(to: string, plantillaCorreo?: string): any {
    const test = {
      to,
      from: 'experienciapadres@innovaschools.edu.co',
      subject: 'Compra Landing Kit',
      message: plantillaCorreo,
    };
    return this.httpClient.post(
      'https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/SesEmailService',
      test
    );
  }

  modifyStateBuyingOrder(state: string, buying_order_id: string): any {
    const cart = { progress: state, buying_order_id };
    return this.httpClient.put(
      'https://ias13u9rp8.execute-api.us-east-1.amazonaws.com/LKGetOrderStatesBackoffice',
      cart
    );
  }
}
