import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class DocusignService {
  constructor(private httpClient: HttpClient) {}

  createEnvelope(accessToken: string, childId: string): any {
    const payload = { accessToken, childId };

    return this.httpClient.post(
      `${environment.API}/docusign/envelopes`,
      payload
    );
  }

  docusignpost(childId: any): any {
    return this.httpClient.post(
      'https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/docusignGenerator',
      childId
    );
  }
  retrieveEnvelopeUrl(accessToken: string, envelopId: string): any {
    const params = new HttpParams()
      .append('accessToken', accessToken)
      .append('envelopeId', envelopId);

    return this.httpClient.get(`${environment.API}/docusign/envelope`, {
      params,
    });
  }

  docusingExtraServices(childId: string, type: string): any {
    const data = {
      childId,
      service_type: type,
    };
    return this.httpClient.post(
      `${environment.API3}/TestAolDocusignExtraServices`,
      data
    );
  }

  docusingPowerForm(data): any {
    return this.httpClient.post(`${environment.API3}/docusignPowerform`, data);
  }
}
