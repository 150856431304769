import { IChildren } from '@interfaces/admin/children.interface';

export class Children {
  child_id?: string;
  user_id?: string;
  first_name?: string;
  first_surname?: string;
  second_surname?: string;
  school_grade?: string;
  gender?: string;
  birthdate: string;
  special_need?: string;
  school_id?: string;
  grado_id?: string;
  hubspot_id?: string;
  existed?: boolean;
  registration_status?: number;
  is_new_child?: boolean;
  screening_answered?: string;
  screening_opportunities?: number;
  image_auth?: boolean;
  valid_docs?: string;
  school?: string;
  document?: any;

  constructor(attrs?: IChildren) {
    this.child_id = attrs.child_id;
    this.user_id = attrs.user_id;
    this.first_name = attrs.first_name ? attrs.first_name.toUpperCase() : '';
    this.first_surname = attrs.first_surname
      ? attrs.first_surname.toUpperCase()
      : '';
    this.second_surname = attrs.second_surname
      ? attrs.second_surname.toUpperCase()
      : '';
    this.school_grade = attrs.school_grade;
    this.gender = attrs.gender;
    this.birthdate = attrs.birthdate;
    this.special_need = attrs.special_need;
    this.school_id = attrs.school_id;
    this.grado_id = attrs.grado_id;
    this.hubspot_id = attrs.hubspot_id;
    this.existed = attrs.existed;
    this.registration_status = attrs.registration_status;
    this.is_new_child = attrs.is_new_child;
    this.screening_answered = attrs.screening_answered;
    this.screening_opportunities = attrs.screening_opportunities;
    this.image_auth = attrs.image_auth;
    this.valid_docs = this.getValidDoc(attrs.valid_docs);
    this.school = attrs.name;
    this.document = attrs.document;
  }

  private getValidDoc(status: boolean): string {
    switch (status) {
      case true:
        return 'Validados';
      case false:
        return 'Rechazados';
      case null:
        return 'Sin validar';
      default:
        return 'Sin validar';
    }
  }
}
