import { IServicesPension } from '@interfaces/services-pension.interface';

export class ServicesPension {
  amount: number;
  amountTemp: number;
  dateExpired: string;
  datePay: string;
  discount: number;
  funEmail: string;
  idFactura: string;
  idHubspot: string;
  idService: number;
  name: string;
  payStade: string;
  period: string;
  dateLastPay: string;
  selected: boolean;
  selectedProntopago: boolean;
  services: ServicesPension[];

  constructor(attrs?: IServicesPension) {
    if (attrs.payStade != 'Anulada') {
      this.modifyService(attrs);
    } else {
      this.idFactura = 'Sin factura';
    }
  }

  private modifyService(attrs?: IServicesPension): void {
    this.amount = this.getAmount(attrs);
    this.amountTemp = parseInt(attrs.amount);
    this.dateExpired = attrs.dateExpired;
    this.datePay = attrs.datePay;
    this.discount = parseInt(attrs.discount);
    this.funEmail = attrs.funEmail;
    this.idFactura = this.getFactura(attrs);
    this.idHubspot = attrs.idHubspot;
    this.idService = attrs.idService;
    this.name = attrs.name;
    this.payStade = attrs.payStade;
    this.period = attrs.period;
    this.dateLastPay = attrs.dateLastPay;
  }

  private getFactura(attrs?: IServicesPension): any {
    if (attrs.payStade != 'Aceptada') {
      return attrs.idFactura;
    }
    return '';
  }

  private getAmount(attrs?: IServicesPension): number {
    if (attrs.payStade != 'Aceptada') {
      return parseInt(attrs.amount) - parseInt(attrs.discount);
    }
    return 0;
  }
}
