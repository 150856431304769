import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IServicesPensionResponse } from '@interfaces/response/services-pension.interface';
import { IServicesPension } from '@interfaces/services-pension.interface';
import { ServicesPension } from '@models/service-pension';
import { map } from 'rxjs/operators';

/**
 * @autor Adrian Avendano Concha
 * @date 25/01/2022
 */
@Injectable({
  providedIn: 'root',
})
export class ServicesPensionService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Obtener los servicios de un estudiante con el id hubspot
   * @autor Adrian Avendano Concha
   * @date 25/01/2022
   */
  getServicesByIdHubspot(id_hubspot: string): any {
    let url = `${environment.API_ENDPOINTS}/pensionesServicios/findservices`;
    url += `?value=${id_hubspot}`;
    return this.httpClient.get(url).pipe(
      map((response: IServicesPensionResponse) => {
        return response.listaServicios.map((service: IServicesPension) => {
          return new ServicesPension(service);
        });
      })
    );
  }

  getPaymentDiscount(infoPayment: any, childId: string): any {
    const url = `${environment.API3}/AolPensionesDiscountCalculation`;
    const data = {
      services: infoPayment.arrayServices,
      months: infoPayment.mounths,
      child_id: childId,
    };
    return this.httpClient.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getPaymentValidation(data: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/services`;
    return this.httpClient.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  generatePayment(form: any): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/services`;
    return this.httpClient.put(url, form).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  validatePeaceAndSave(child_id: string): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/services?child_id=${child_id}&date=true`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
