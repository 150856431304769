import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const ApiURL =
  'https://outiqyoot8.execute-api.us-east-1.amazonaws.com/test/upload-routes';

@Injectable({
  providedIn: 'root',
})
export class RouteRequestBulkService {
  // eslint-disable-next-line no-unused-vars
  constructor(private httpClient: HttpClient) {}

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(ApiURL, formData);
  }
}
